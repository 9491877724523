/* eslint-disable @typescript-eslint/naming-convention */
import { gql } from 'graphql-request';
import { sumBy } from 'lodash';
import { useQuery } from 'react-query';
import { Trade } from '~/types';
import { DIVIDER } from '~/utils/const';
import useGraphqlClient from './useGraphqlClient';

const ObjktVolumeQuery = gql`
  query ObjktVolume($objktId: bigint) {
    trade(
      where: {
        token: {
          id: {
            _eq: $objktId
          }
        }
      }
    ) {
      amount
      swap {
        price
        creator_id
      }
      token {
        creator_id
      }
    }
  }
`;

const getTradesVolume = (trades: Trade[]) => sumBy((trades as Trade[]), (trade) => trade.swap.price * trade.amount || 0) / DIVIDER;

const useObjktVolume = (objktId: number) => {
  const gqlClient = useGraphqlClient();
  return useQuery<{
    total: number;
    primary: number;
    secondary: number;
  }>(
    ['objkt.volume', objktId],
    async () => {
      const { trade: trades = [] } = await gqlClient<{ trade: Trade[] }>(
        ObjktVolumeQuery,
        { objktId },
      );
      const total = getTradesVolume(trades);
      const primary = getTradesVolume(trades.filter(({ swap, token }) => swap.creator_id === token.creator_id));
      const secondary = getTradesVolume(trades.filter(({ swap, token }) => swap.creator_id !== token.creator_id));
      return {
        total,
        primary,
        secondary,
      };
    },
    { enabled: !!objktId },
  );
};

export default useObjktVolume;
