/* eslint-disable @typescript-eslint/naming-convention */
import { GetServerSidePropsContext, GetServerSideProps } from 'next';
import { tokenFragmentXLite } from '~/graphql';
import { getBlockedWallets } from '~/hooks/useBlockedWallets';
import { graphqlClient } from '~/hooks/useGraphqlClient';
import { getObjktData } from '~/hooks/useObjktData';
import { Objkt } from '~/types';
import { getUserName, isBrowser, isServerReq, removeNullish, replaceIPFS2 } from '~/utils';
import { imgixClient } from '~/utils/imgix';
import { objktIsOfType } from '~/utils/mime';

export type ObjktMetaTags = {
  title: string;
  description: string;
  image?: string;
  id: string;
};

export type ObjktPageProps = {
  objkt?: Objkt;
  metaTags?: ObjktMetaTags;
};

type QueryParams = {
  id: string;
};

export const getObjktMetatags = (objkt: Objkt): ObjktMetaTags => {
  if (!objkt) return null;
  const {
    id,
    title,
    description,
    // imageUrl,
    display_uri,
  } = objkt;
  const isAudio = objktIsOfType(objkt, 'audio');
  const displayUri = replaceIPFS2(display_uri);
  const image = isBrowser ? null : imgixClient.buildURL(displayUri, {
    w: 1200,
    h: 675,
    fit: 'fillmax',
    fill: 'blur',
    /*
    w: 300,
    ar: '1:1',
    fit: 'crop',
    crop: 'faces,entropy',
    ---
    ar: '1:1',
    crop: 'faces,entropy',
    'fill-color': '28285D',
    */
  });
  if (isAudio) {
    const creatorName = getUserName(objkt.creator);
    return removeNullish({
      id,
      image,
      title: `${title} - NFT track by ${creatorName}`,
      description: `Listen to ${title} on HIC.AF. ${creatorName} · Track · 2021.`,
    }) as ObjktMetaTags;
  }
  return removeNullish({
    id,
    image,
    title: title || `Objkt ${id}`,
    description,
  }) as ObjktMetaTags;
};

const getObjktPageProps: GetServerSideProps<ObjktPageProps> = async (
  context: GetServerSidePropsContext<QueryParams>,
): Promise<{ props: ObjktPageProps }> => {
  try {
    if (!isServerReq(context.req)) {
      return {
        props: {},
      };
    }
    const { id } = context?.params;
    const blockedWallets = await getBlockedWallets();
    const objkt = await getObjktData({
      id: +id,
      fragment: tokenFragmentXLite,
      blockedWallets,
      client: graphqlClient,
    });
    return {
      props: {
        metaTags: getObjktMetatags(objkt),
        objkt,
      },
    };
  } catch (err) {
    return {
      props: {},
    };
  }
};

export default getObjktPageProps;
