import React from 'react';
import Link from 'next/link';
import parseISO from 'date-fns/parseISO';
import { BiLinkExternal } from 'react-icons/bi';
import styled from 'styled-components';
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { activityEmojis, formatAmount, xtz } from '~/utils';
import Table from '~/components/Table';
import { DIVIDER } from '~/utils/const';
import { calendar } from '~/hooks/useTimeAgo';
import ShowMoreButton from './ShowMoreButton';
import { NftAsset } from './TokenAsset';
import ExternalLink from './ExternalLink';
import useBreakpoint from '~/hooks/useBreakpoint';
import { Activity, ActivityType, Objkt } from '~/types';
import Select from './Select';
import UserTableItem from './UserTableItem';
import LoadIcon from './LoadIcon';
import ActivityEmoji from './ActivityEmoji';
import { ADDRESSES } from '~/utils/addresses';

const StyledSelect = styled(Select)`
  margin-bottom: 10px;
  position: relative;
  text-transform: capitalize;
  option {
    text-transform: capitalize;
  }
`;

const SelectContainer = styled.div`
  display: table;
`;

const activityLabel = {
  burn: 'Burn',
  listing: 'Listing',
  transfer: 'Transfer',
  buy: 'Buy',
  sell: 'Sell',
  sale: 'Sale',
  salePrimary: 'Primary sale',
  saleSecondary: 'Secondary sale',
  mint: 'Mint',
};

const ActivityTable: React.FC<{
  items: Activity[];
  filters: ActivityType[];
  isObjktPage?: boolean;
  isLoading?: boolean;
}> = ({
  items,
  filters = [],
  isObjktPage,
  isLoading,
}) => {
  const breakpoint = useBreakpoint();
  const [filter, setFilter] = React.useState<string>();
  const allItems = items
    .map((item) => ({
      ...item,
      ...(item.buyer?.address === ADDRESSES.burn ? {
        type: 'burn' as ActivityType,
      } : {}),
    }));
  const filteredItems = allItems.filter(({ type }) => (filter ? type === filter : true));
  const rpp = React.useMemo(() => 10, []);
  const [limit, setLimit] = React.useState<number>(rpp);
  const limitedItems = filteredItems.slice(0, limit);
  const canShowMore = filteredItems.length > limit;
  const handleShowMore = () => setLimit((l) => l + rpp);
  const filterCounts = filters.reduce((acc, f) => ({
    ...acc,
    [f]: formatAmount(allItems.filter(({ type }) => type === f).length),
  }), {});
  React.useEffect(() => {
    setLimit(rpp);
  }, [filter, rpp]);
  return (
    <>
      <SelectContainer>
        <StyledSelect value={ filter } onChange={ (e) => setFilter(e.target.value) }>
          <option value="">- All -</option>
          {
            filters.map((f) => (
              <option key={ `activity.filter.${f}` } value={ f }>
                { `${activityEmojis[f]} ${activityLabel[f]} (${filterCounts[f]})` }
              </option>
            ))
          }
        </StyledSelect>
      </SelectContainer>
      <Table key={ `activity.${breakpoint}.${isObjktPage}.${filter}` }>
        <Thead>
          <Tr>
            {
              isObjktPage ? null : (
                <Th>Objkt</Th>
              )
            }
            {
              isObjktPage ? null : (
                <Th>Objkt ID</Th>
              )
            }
            <Th>Type</Th>
            <Th>Date</Th>
            {
              isObjktPage ? null : (
                <Th>Creator</Th>
              )
            }
            <Th>From</Th>
            <Th>To</Th>
            <Th style={ { textAlign: 'right' } }>Quantity</Th>
            <Th style={ { textAlign: 'right' } }>Price</Th>
            <Th style={ { textAlign: 'right' } }>TX</Th>
          </Tr>
        </Thead>
        <Tbody>
          {
            isLoading ? (
              <tr>
                <td>
                  <div style={ { paddingTop: 8 } }>
                    <LoadIcon $animating />
                  </div>
                </td>
              </tr>
            ) : limitedItems.filter(Boolean).map(({
              id,
              type,
              timestamp,
              buyer,
              seller,
              token = {} as Objkt,
              price = 0,
              quantity = 0,
              ophash,
            }) => (
              (
                <Tr
                  key={ `trade.${id}.${type}.${timestamp}` }
                >
                  {
                    isObjktPage ? null : (
                      <Td>
                        <div style={ { width: 50, height: 50 } }>
                          <Link href={ `/o/${token.id}` } passHref>
                            <a>
                              <NftAsset { ...token } />
                            </a>
                          </Link>
                        </div>
                      </Td>
                    )
                  }
                  {
                    isObjktPage ? null : (
                      <Td>
                        <Link href={ `/o/${token.id}` } passHref>
                          <a>
                            { token.id }
                          </a>
                        </Link>
                      </Td>
                    )
                  }
                  <Td>
                    <ActivityEmoji type={ type } />
                    <span style={ { marginLeft: 5 } }>
                      {activityLabel[type]}
                    </span>
                  </Td>
                  <Td>
                    { calendar(timestamp && parseISO(timestamp)) }
                  </Td>
                  {
                    isObjktPage ? null : (
                      <Td>
                        <UserTableItem user={ token?.creator } />
                      </Td>
                    )
                  }
                  <Td>
                    <UserTableItem user={ seller } />
                  </Td>
                  <Td>
                    <UserTableItem user={ buyer } />
                  </Td>
                  <Td style={ { textAlign: 'right' } }>{quantity}</Td>
                  <Td style={ { textAlign: 'right' } }>
                    {price > 0 ? `${formatAmount(price / DIVIDER, 2)} ${xtz}` : '-'}
                  </Td>
                  <Td style={ { textAlign: 'right' } }>
                    {
                      ophash ? (
                        <ExternalLink href={ `https://tzkt.io/${ophash}` }>
                          <BiLinkExternal />
                        </ExternalLink>
                      ) : null
                    }
                  </Td>
                </Tr>
              )
            ))
          }
        </Tbody>
      </Table>
      {
        canShowMore ? (
          <ShowMoreButton onClick={ handleShowMore }>Show more</ShowMoreButton>
        ) : null
      }
    </>
  );
};

export default ActivityTable;
