import React from 'react';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';
import useBatchOperation from '~/hooks/useBatchOperation';
import useCurrentUserHoldings from '~/hooks/useCurrentUserHoldings';
import { Objkt } from '~/types';
import { formatAmount } from '~/utils';
import Button from './Button';
import ButtonSquare from './ButtonSquare';
import Emoji from './Emoji';
import Input from './Input';
import Modal, { ModalBody, ModalCloseButton, ModalHeader } from './Modal';
import TextLight from './TextLight';
import TextTruncated from './TextTruncated';
import { TxError, TxMining, TxSuccess } from './TxModalContent';

const ObjktBurnButton: React.FC<{
  objkt: Objkt;
}> = ({
  objkt,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [quantity, setQuantity] = React.useState<number>(1);
  const holdings = useCurrentUserHoldings(objkt);
  const maxQuantity = holdings.quantity;
  const { burn } = useHicetnuncContext();
  const { txStatus, txError, txHash, txRun, txCancel } = useBatchOperation();
  const handleConfirm = async () => {
    if (window.confirm(`You are about to burn ${quantity} objkt${quantity > 1 ? 's' : ''}. It will be irreversible. Are you sure?`)) {
      await txRun(() => burn({
        objktId: objkt.id,
        quantity,
      }));
    }
  };
  React.useEffect(() => {
    txCancel();
    setQuantity(1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  return (
    <>
      <ButtonSquare onClick={ () => setIsOpen((o) => !o) }>
        <Emoji v="🔥" style={ { transform: 'translateY(2px)' } } />
      </ButtonSquare>
      <Modal isOpen={ isOpen } handleClose={ () => setIsOpen(false) }>
        <ModalHeader>
          <TextTruncated as="h2" style={ { margin: 0 } }>
            {`Burn ${objkt.title}`}
          </TextTruncated>
          <ModalCloseButton onClick={ () => setIsOpen(false) } />
        </ModalHeader>
        <ModalBody style={ { padding: 30, textAlign: 'center' } }>
          {
            txStatus === 'idle' || txStatus === 'pending' ? (
              <>
                {
                  maxQuantity > 1 ? (
                    <>
                      <div style={ { marginBottom: 20 } }>
                        How many editions do you want to burn?
                      </div>
                      <div style={ { marginBottom: 10 } }>
                        <input
                          type="range"
                          min={ 1 }
                          max={ maxQuantity }
                          value={ quantity }
                          onChange={ (e) => setQuantity(+e.target.value) }
                          style={ { cursor: 'pointer', margin: 'auto' } }
                        />
                      </div>
                      <div style={ { marginBottom: 28 } }>
                        <Input
                          type="number"
                          min={ 1 }
                          max={ maxQuantity }
                          step="1"
                          value={ quantity }
                          onChange={ (e) => setQuantity(+e.target.value) }
                          style={ { width: 120 } }
                        />
                      </div>
                    </>
                  ) : null
                }
                <Button onClick={ handleConfirm } $danger disabled={ txStatus === 'pending' || !quantity }>
                  {txStatus === 'pending' ? 'Burning, hold on...' : `Burn ${formatAmount(quantity)} edition${quantity > 1 ? 's' : ''}`}
                </Button>
                <TextLight style={ { marginTop: 10 } }>
                  { `You own ${holdings.total} edition${holdings.total > 1 ? 's' : ''}` }
                </TextLight>
              </>
            ) : txStatus === 'mining' ? (
              <TxMining txHash={ txHash } />
            ) : txStatus === 'success' ? (
              <TxSuccess txHash={ txHash } handleClick={ () => setIsOpen(false) } />
            ) : txStatus === 'error' ? (
              <TxError txError={ txError } handleClick={ () => setIsOpen(false) } />
            ) : null
          }
        </ModalBody>
      </Modal>
    </>
  );
};

export default ObjktBurnButton;
