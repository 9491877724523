import React from 'react';
import TokenList from './TokenList';
import useSearchObjkts, { useSearchObjktsCount } from '~/hooks/useSearchObjkts';
import ObjktFilterRow from './ObjktFilterRow';
import FetchMoreButton from './FetchMoreButton';

type Props = {
  address: string;
  exclude?: number[];
  rpp?: number;
  showFilters?: boolean;
  noShowMore?: boolean;
};

const CreatorFeed: React.FC<Props> = ({
  address,
  exclude = [],
  rpp: limit = 20,
  showFilters,
  noShowMore,
}) => {
  const {
    status,
    tokens = [],
    fetchNextPage,
    isFetchingNextPage,
  } = useSearchObjkts({
    creators: [address],
    limit,
  });
  const { count, countLoading } = useSearchObjktsCount({
    creators: [address],
    exclude,
    limit,
  });
  const canFetchMore = !noShowMore && tokens.length > 0 && tokens.length < count;
  return (
    <>
      {
        showFilters ? (
          <ObjktFilterRow count={ count } countLoading={ countLoading } />
        ) : null
      }
      <TokenList tokens={ tokens } isLoading={ status === 'loading' } />
      {
        canFetchMore ? (
          <FetchMoreButton { ...{ fetchNextPage, isFetchingNextPage } } />
        ) : null
      }
    </>
  );
};

export default CreatorFeed;
