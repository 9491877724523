import React from 'react';
import Link from 'next/link';
import parseISO from 'date-fns/parseISO';
import { getUserLink } from '~/graphql';
import { Objkt } from '~/types';
import { calendar } from '~/hooks/useTimeAgo';
import FlexRow from './FlexRow';
import UserAvatar from './UserAvatar';
import UserTableItem from './UserTableItem';
import UserPopover from './UserPopover';

const TokenCreatorData: React.FC<{ objkt: Objkt }> = ({ objkt }) => {
  const {
    creator,
    timestamp,
  } = objkt;
  const mintedAgo = timestamp && calendar(parseISO(timestamp));
  return (
    <FlexRow style={ { marginTop: 4, marginBottom: 0 } }>
      <UserPopover user={ creator }>
        <div style={ { marginRight: 10 } }>
          <Link href={ getUserLink(creator) } passHref>
            <a>
              <UserAvatar user={ creator } sizes={ { fallback: 34 } } />
            </a>
          </Link>
        </div>
      </UserPopover>
      <div>
        {
          mintedAgo ? (
            <div style={ { marginBottom: -2 } }>{`Minted ${mintedAgo}`}</div>
          ) : null
        }
        <FlexRow>
          <span style={ { marginRight: 5 } }>by</span>
          <UserTableItem user={ objkt.creator } noAvatar />
        </FlexRow>
      </div>
    </FlexRow>
  );
};

export default TokenCreatorData;
