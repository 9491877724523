import { gql } from 'graphql-request';
import { useQuery } from 'react-query';
import { useDataContext } from '~/contexts/Data';
import { tokenFragment, transformToken } from '~/graphql';
import useGraphqlClient from './useGraphqlClient';

const getObjktQuery = (fragment: string = tokenFragment) => gql`
  query objkt($id: bigint!) {
    token_by_pk(id: $id) {
      ${fragment}
    }
  }
`;

export const getObjktData = async ({
  id,
  fragment,
  blockedWallets = [],
  client,
}: {
  id: number;
  fragment?: string;
  blockedWallets: string[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  client: any;
}) => {
  if (!id) return null;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { token_by_pk } = await client(
    getObjktQuery(fragment),
    { id },
  );
  return transformToken(token_by_pk, blockedWallets);
};

const useObjktData = (id: number) => {
  const { blockedWallets } = useDataContext();
  const client = useGraphqlClient();
  return useQuery(
    ['objkt', id],
    async () => getObjktData({
      id,
      fragment: tokenFragment,
      blockedWallets,
      client,
    }),
    { enabled: !!id },
  );
};

export default useObjktData;
