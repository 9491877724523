/* eslint-disable max-len */
import React from 'react';
import { uniqBy } from 'lodash';
import styled from 'styled-components';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';
import useBatchOperation from '~/hooks/useBatchOperation';
// import useCurrentUserHoldings from '~/hooks/useCurrentUserHoldings';
import { Objkt, User } from '~/types';
import { formatAmount, tezosAddressRegex } from '~/utils';
import Button from './Button';
import Emoji from './Emoji';
import Input, { inputCss } from './Input';
import Modal, { ModalBody, ModalCloseButton, ModalFooter, ModalHeader } from './Modal';
import TextTruncated from './TextTruncated';
import { TxError, TxMining, TxSuccess } from './TxModalContent';
import UserTableItem from './UserTableItem';
import useUsers from '~/hooks/useUsers';
import LoadIcon from './LoadIcon';
import StatusText from './StatusText';
import FlexRow from './FlexRow';
import ButtonSquare from './ButtonSquare';
import TextLight from './TextLight';
import useCurrentUserHoldings from '~/hooks/useCurrentUserHoldings';

const RemoveButton = styled.button.attrs({
  type: 'button',
})`
  all: unset;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  margin-left: 10px;
`;

const RecipientRow = styled(FlexRow)`
    margin: 2px 0;
`;

const ObjktTransferButton: React.FC<{
  objkt: Objkt;
}> = ({
  objkt,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [isRecipientsOpen, setIsRecipientsOpen] = React.useState<boolean>(false);
  const [quantities, setQuantities] = React.useState<{ [k: string]: number }>({});
  const [recipients, setRecipients] = React.useState<string[]>([]);
  const { data: users = [], isLoading: isLoadingUsers, refetch: refetchUsers } = useUsers(recipients);
  const recipientRows: { user: User; quantity: number; }[] = users.map((user) => ({
    user,
    quantity: quantities[user.address] || 1,
  }));
  const totalQuantity = Object.values(quantities).reduce((q, v) => q + v, 0);
  const holdings = useCurrentUserHoldings(objkt);
  const { batchTransfer } = useHicetnuncContext();
  const { txStatus, txError, txHash, txRun, txCancel } = useBatchOperation();
  const handleConfirm = async () => {
    const data = recipientRows.map(({ user, quantity }) => ({
      quantity,
      address: user.address,
      objktId: objkt.id,
    }));
    await txRun(() => batchTransfer(data));
  };
  const handleAdd = (items = []) => {
    setRecipients((r) => uniqBy([...r, ...items], (x) => x));
    setIsRecipientsOpen(false);
  };
  const handleRemove = (address) => setRecipients((r) => r.filter((a) => a !== address));
  React.useEffect(() => {
    txCancel();
    setQuantities({});
    setRecipients([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  React.useEffect(() => {
    refetchUsers();
    setQuantities((q) => recipients.reduce((o, a) => ({
      ...o,
      [a]: q[a] || 1,
    }), {}));
  }, [JSON.stringify(recipients?.sort()), refetchUsers]);
  return (
    <>
      <ButtonSquare onClick={ () => setIsOpen((o) => !o) }>
        <Emoji v="✈️" style={ { transform: 'translateY(2px)' } } />
      </ButtonSquare>
      <Modal isOpen={ isOpen } handleClose={ () => setIsOpen(false) }>
        <ModalHeader>
          <TextTruncated as="h2" style={ { margin: 0 } }>
            {`Transfer ${objkt.title} to...`}
          </TextTruncated>
          <ModalCloseButton onClick={ () => setIsOpen(false) } />
        </ModalHeader>
        <ModalBody style={ { padding: 20 } }>
          {
            txStatus === 'idle' || txStatus === 'pending' ? (
              <>
                {
                  isLoadingUsers ? <LoadIcon $animating /> : recipientRows.length > 0 ? (
                    <>
                      <FlexRow style={ { fontWeight: 'bold', marginBottom: 10 } }>
                        <div style={ { flex: 1 } }>
                          Recipient
                        </div>
                        <div style={ { alignItems: 'right', marginRight: 20 } }>
                          Quantity
                        </div>
                      </FlexRow>
                      {
                        recipientRows.map(({ user, quantity }) => (
                          <RecipientRow key={ `recipient.${user.address}` }>
                            <div style={ { flex: 1 } }>
                              <UserTableItem user={ user } />
                            </div>
                            <Input
                              type="number"
                              placeholder="Quantity"
                              step="1"
                              min={ 1 }
                              max={ null }
                              value={ quantity }
                              onChange={
                                (e) => setQuantities((q) => ({
                                  ...q,
                                  [user.address]: +e.target.value,
                                }))
                              }
                              style={ { width: 100 } }
                            />
                            <div style={ { width: 20 } }>
                              {
                                recipients.length > 1 ? (
                                  <RemoveButton onClick={ () => handleRemove(user.address) }>
                                    x
                                  </RemoveButton>
                                ) : null
                              }
                            </div>
                          </RecipientRow>
                        ))
                      }
                    </>
                  ) : (
                    <div>No recipients added yet</div>
                  )
                }
              </>
            ) : txStatus === 'mining' ? (
              <TxMining txHash={ txHash } />
            ) : txStatus === 'success' ? (
              <TxSuccess txHash={ txHash } handleClick={ () => setIsOpen(false) } />
            ) : txStatus === 'error' ? (
              <TxError txError={ txError } handleClick={ () => setIsOpen(false) } />
            ) : null
          }
        </ModalBody>
        {
          txStatus === 'idle' || txStatus === 'pending' ? (
            <ModalFooter>
              <Button onClick={ () => setIsRecipientsOpen(true) }>Select recipients</Button>
              <TextLight style={ { marginLeft: 'auto' } }>
                { `You own ${holdings.total} edition${holdings.total > 1 ? 's' : ''}` }
              </TextLight>
              {
                recipients.length > 0 ? (
                  <Button
                    onClick={ handleConfirm }
                    $primary
                    disabled={ txStatus === 'pending' || !recipients.length }
                    style={ { marginLeft: 'auto' } }
                  >
                    {txStatus === 'pending' ? 'Transfering, hold on...' : `Transfer ${formatAmount(totalQuantity)} edition${totalQuantity > 1 ? 's' : ''} to ${recipients.length} recipient${recipients.length > 1 ? 's' : ''}`}
                  </Button>
                ) : null
              }
            </ModalFooter>
          ) : null
        }
      </Modal>
      <ModalRecipients
        isOpen={ isRecipientsOpen }
        handleClose={ () => setIsRecipientsOpen(false) }
        handleSubmit={ handleAdd }
      />
    </>
  );
};

export default ObjktTransferButton;

const Textarea = styled.textarea.attrs({
  spellCheck: false,
  autoCorrect: 'none',
  autoCapitalize: 'none',
})`
  ${inputCss}
  height: 150px;
`;

const ModalRecipients = ({
  isOpen,
  handleClose,
  handleSubmit,
}) => {
  const [value, setValue] = React.useState<string>('');
  const addresses = React.useMemo(() => uniqBy(
    value
      ?.match(tezosAddressRegex)
      ?.map((v) => v.trim()) || [],
    (x) => x,
  ), [value]);
  const onClickSubmit = () => handleSubmit(addresses);
  React.useEffect(() => {
    setValue('');
  }, [isOpen]);
  return (
    <Modal isOpen={ isOpen } handleClose={ handleClose }>
      <ModalHeader>
        <TextTruncated as="h2" style={ { margin: 0 } }>
          Add transfer recipients
        </TextTruncated>
        <ModalCloseButton onClick={ handleClose } />
      </ModalHeader>
      <ModalBody style={ { padding: 20 } }>
        <Textarea
          value={ value }
          onChange={ (e) => setValue(e.target.value) }
        />
        <StatusText>Type the addresses for your recipients, separated by commas.</StatusText>
        {
          addresses.length > 0 ? (
            <StatusText style={ { paddingTop: 0 } }>
              { `${formatAmount(addresses.length)} address${addresses.length > 1 ? 'es' : ''} detected` }
            </StatusText>
          ) : null
        }
      </ModalBody>
      <ModalFooter>
        <Button onClick={ onClickSubmit } disabled={ addresses.length === 0 }>
          { `Add recipient${addresses.length > 1 ? 's' : ''}` }
        </Button>
      </ModalFooter>
    </Modal>
  );
};
