/* eslint-disable no-underscore-dangle */
import axios from 'axios';
import { useQuery } from 'react-query';
import uniqBy from 'lodash/uniqBy';
import keyBy from 'lodash/keyBy';
import { ADDRESSES } from '~/utils/addresses';
import { getUsersData } from '~/hooks/useUsers';
import useGraphqlClient from './useGraphqlClient';

export const getObjktTransfers = (objktId: number) => axios.get(`https://api.tzkt.io/v1/accounts/${ADDRESSES.objkts}/operations`, {
  params: {
    'parameter.[*].txs.[*].token_id': objktId,
    'parameter.[*].from_.ni': [ADDRESSES.v1, ADDRESSES.v2].join(','),
    'sender.ni': ['KT1Dno3sQZwR5wUCWxzaohwuJwG3gX1VWj1Z', 'KT1FvqJwEDWb1Gwc55Jd1jjTHRVWbYKUUpyq', ADDRESSES.v2].join(','),
    status: 'applied',
    limit: 1000,
  },
})
  .then(({ data = [] }) => data.filter(({ type }) => type === 'transaction')
    .map(({
      timestamp,
      sender,
      parameter,
      hash,
    }) => ({
      timestamp,
      from: sender.address,
      to: parameter.value?.[0]?.txs?.[0]?.to_,
      objktId: +parameter.value?.[0]?.txs?.[0].token_id,
      quantity: +parameter.value?.[0]?.txs?.[0].amount,
      ophash: hash,
    })));

const useObjktTransfers = (objktId: number) => {
  const gqlClient = useGraphqlClient();
  return useQuery(
    ['objkt.transfers', objktId],
    async () => {
      const transfers = await getObjktTransfers(objktId);
      const usersIds = uniqBy(transfers.reduce((acc, { to, from }) => ([...acc, to, from]), []), (x) => x) as string[];
      const users = await getUsersData(usersIds, gqlClient);
      const indexedUsers = keyBy(users, 'address');
      return transfers.map((o) => ({
        ...o,
        from: indexedUsers[o.from],
        to: indexedUsers[o.to],
      }));
    },
    { enabled: !!objktId },
  );
};

export default useObjktTransfers;
