import React from 'react';
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai';
import styled from 'styled-components';
import { useDataContext } from '~/contexts/Data';

const LikeButtonContainer = styled.button.attrs({
  type: 'button',
})<{
  $clicked?: boolean;
}>`
  all: unset;
  cursor: pointer;
  width: 30px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: transform 150ms ease;
  transform: scale(${({ $clicked }) => ($clicked ? 0.9 : 1)});
`;

const LikeIcon = styled(AiOutlineHeart)<{ size?: number }>`
  fill: ${({ theme }) => theme.colors.textLight};
`;

const LikedIcon = styled(AiFillHeart)<{ size?: number }>`
  fill: ${({ theme }) => theme.colors.alert};
`;

export const useLike = (id: number) => {
  const { favorites = [], setFavorites } = useDataContext();
  const isFavorite = favorites?.includes(+id);
  // @ts-ignore
  const favorite = (id_: number) => setFavorites((f = []) => [...f, +id_]);
  // @ts-ignore
  const unfavorite = (id_: number) => setFavorites((f = []) => f.filter((a) => a !== +id_));
  const toggle = () => {
    if (isFavorite) {
      unfavorite(+id);
    } else {
      favorite(+id);
    }
  };
  return {
    isFavorite,
    toggle,
  };
};

const LikeButton: React.FC<{
  id: number;
  size?: number;
  className?: string;
}> = ({
  id,
  size = 22,
  className,
}) => {
  const {
    isFavorite,
    toggle,
  } = useLike(id);
  const [clicked, setClicked] = React.useState<boolean>(false);
  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    toggle();
  };
  return (
    <LikeButtonContainer
      className={ className }
      onClick={ handleClick }
      onMouseDown={ () => setClicked(true) }
      onMouseUp={ () => setClicked(false) }
      onPointerDown={ () => setClicked(true) }
      onPointerUp={ () => setClicked(false) }
      $clicked={ clicked }
    >
      { isFavorite ? <LikedIcon size={ size } /> : <LikeIcon size={ size } /> }
    </LikeButtonContainer>
  );
};

export default LikeButton;
