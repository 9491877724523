/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/naming-convention */
import { useRouter } from 'next/router';
import styled from 'styled-components';
import React from 'react';
import Link from 'next/link';
import parseISO from 'date-fns/parseISO';
import dynamic from 'next/dynamic';
import orderBy from 'lodash/orderBy';
import { Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { formatAmount, replaceIPFS, xtz } from '~/utils';
import { getUserLink, transformToken } from '~/graphql';
import { NftAsset } from '~/components/TokenAsset';
import CreatorFeed from '~/components/CreatorFeed';
import LikeButton from '~/components/LikeButton';
import { calendar } from '~/hooks/useTimeAgo';
import { media } from '~/styles/media';
import Modal, { FixedModalCloseButton } from '~/components/Modal';
// import useObjktDetails from '~/hooks/useObjktDetails';
import { Activity, Objkt, Swap } from '~/types';
import ActivityTable from '~/components/ActivityTable';
import ShowMoreButton from '~/components/ShowMoreButton';
import { DIVIDER } from '~/utils/const';
import Table from '~/components/Table';
import StatusText from '~/components/StatusText';
import useCurrentUserHoldings from '~/hooks/useCurrentUserHoldings';
import { getObjktMetatags, ObjktPageProps } from '~/pageProps/objkt';
import useObjktData from '~/hooks/useObjktData';
import Head from '~/components/Head';
import StolenBanner from '~/components/StolenBanner';
import TextLight from '~/components/TextLight';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';
import EditSwapButton from '~/components/EditSwapButton';
import CancelSwapButton from '~/components/CancelSwapButton';
import { getObjktType, VIDEO_MIMETYPES } from '~/utils/mime';
import TextLoader from '~/components/TextLoader';
import LoaderStyle from '~/styles/Loader';
import Button, { buttonHeight } from '~/components/Button';
import { useDataContext } from '~/contexts/Data';
import TokenCreatorData from '~/components/TokenCreatorData';
import TextLimitable from '~/components/TextLimitable';
import UserTableItem from '~/components/UserTableItem';
import useObjktTransfers from '~/hooks/useObjktTransfers';
import ShareButton from '~/components/ShareButton';
import FlexRow from '~/components/FlexRow';
import { colors } from '~/styles/themes';
import useObjktVolume from '~/hooks/useObjktVolume';
import ObjktActionsButton from '~/components/ObjktActionsButton';
import Layout from '~/components/Layout';
import ObjktBurnButton from '~/components/ObjktBurnButton';
import ObjktTransferButton from '~/components/ObjktTransferButton';

const BuyButton = dynamic(() => import('~/components/BuyButton'), {
  ssr: false,
});

const SellButton = dynamic(() => import('~/components/SellButton'), {
  ssr: false,
});

const TagsList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -3px;
`;

const Tag = styled.a`
  background: ${({ theme }) => theme.colors.foreground};
  padding: 3px 6px;
  margin: 3px;
`;

const TokenAssetContainer = styled.div`
  cursor: pointer;
  position: relative;
  background-color: ${({ theme }) => theme.colors.foregroundLight};
  height: 400px;
  overflow: hidden;
  > div {
    height: 100%;
    width: 100%;
  }
`;

const Section = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  word-break: break-all;
`;

const TopSection = styled(Section)`
  display: block;
  ${media.tablet`
    text-align: center;
    margin: auto;
    margin-top: 30px;
  `}
`;

const InfoSection: React.FC<Objkt> = ({
  // id,
  description,
}) => {
  // const { data: details } = useObjktDetails(id);
  // const desc = details?.token_info?.description || description || '-';
  const desc = description || '-';
  return (
    <Section style={ { marginBottom: 5 } }>
      <TextLimitable text={ desc } />
    </Section>
  );
};

const StyledBuyButton = styled(BuyButton)`
  margin-top: 0px;
  width: auto;
`;

const StyledBuyButtonLoader = styled(Button)`
  margin-top: 8px;
  width: 120px;
  height: ${buttonHeight - 2}px;
  ${LoaderStyle}
`;

const StyledSellButton = styled(SellButton)`
  margin-top: 8px;
`;

/*
const UnlistButton = dynamic(() => import('~/components/UnlistButton'), {
  ssr: false,
});
const StyledUnlistButton = styled(UnlistButton)`
  margin-top: 8px;
`;
*/

const PageContainer = styled.div`
  display: grid;
  grid-template-columns: 500px 1fr;
  grid-gap: 30px;
  ${media.tablet`
    display: block;
  `}
`;

const ButtonsRow = styled.div`
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  width: fit-content;
  ${media.mobile`
    margin-top: 10px;
    flex-direction: row;
  `}
  ${media.tablet`
    position: static;
    margin: 10px 0;
  `}
`;

const SectionTitle = styled.h3`
  margin-bottom: 5px;
`;

const CollectorSection = ({ token_holders = [] }) => {
  const items = orderBy(
    token_holders,
    ['quantity', (o) => o.holder.name.toLowerCase(), 'holder.address'],
    ['desc', 'asc', 'asc'],
  );
  const [limit, setLimit] = React.useState<number>(5);
  const itemsLimited = items.slice(0, limit);
  const canShowMore = items.length > limit;
  const handleShowMore = () => setLimit(items.length);
  return (
    <>
      {
        token_holders?.length > 0 ? (
          <Section>
            <SectionTitle>
              Collectors (
              {token_holders.length}
              )
            </SectionTitle>
            <Table>
              <Thead>
                <Tr>
                  <Th>Collector</Th>
                  <Th style={ { textAlign: 'right' } }>Quantity</Th>
                </Tr>
              </Thead>
              <Tbody>
                {
                  itemsLimited.map(({ holder, quantity }) => (
                    <Tr key={ `collector.${holder.address}` }>
                      <Td>
                        <UserTableItem user={ holder } />
                      </Td>
                      <Td style={ { textAlign: 'right' } }>
                        { quantity }
                      </Td>
                    </Tr>
                  ))
                }
              </Tbody>
            </Table>
            {
              canShowMore ? (
                <ShowMoreButton onClick={ handleShowMore }>
                  Show all collectors
                </ShowMoreButton>
              ) : null
            }
          </Section>
        ) : null
      }
    </>
  );
};

const TagSection: React.FC<Objkt> = ({ tags = [] }) => (
  <Section>
    {/*
    <SectionTitle>Tags</SectionTitle>
    */}
    {
      tags.length === 0 ? '-' : (
        <TagsList>
          {
            tags.map((tag) => (
              <Link key={ `tag.${tag}` } href={ `/tags/${tag}` } passHref>
                <Tag>{tag}</Tag>
              </Link>
            ))
          }
        </TagsList>
      )
    }
  </Section>
);

const Title = styled.h1`
  margin-top: 2px;
  margin-bottom: 5px;
  line-height: 1.9rem;
`;

const TokenAssetWithModal: React.FC<{ objkt: Objkt }> = ({ objkt }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const hasModal = !VIDEO_MIMETYPES.includes(objkt.mimeType);
  const handleClick = () => hasModal && setIsOpen(true);
  const handleCloseModal = () => setIsOpen(false);
  const src = replaceIPFS(objkt.artifact_uri);
  return (
    <>
      <TokenAssetContainer onClick={ handleClick }>
        <NftAsset { ...objkt } src={ src } />
      </TokenAssetContainer>
      <Modal
        isOpen={ isOpen }
        handleClose={ handleCloseModal }
        backdropStyle={
          {
            backgroundColor: 'black',
            padding: 0,
          }
        }
        windowStyle={
          {
            borderWidth: 0,
          }
        }
      >
        <div style={ { position: 'relative', width: '100%', height: '100%' } }>
          <NftAsset { ...objkt } src={ src } $active />
          <FixedModalCloseButton onClick={ handleCloseModal } />
        </div>
      </Modal>
    </>
  );
};

const ActivityFeed: React.FC<Objkt> = (objkt) => {
  const { id: objktId, trades = [], swaps = [] } = objkt as Objkt;
  const { data: transfers = [] } = useObjktTransfers(objktId);
  const items = orderBy([
    ...transfers.map((o) => ({
      ...o,
      type: 'transfer',
      seller: o.from,
      buyer: o.to,
    })),
    ...trades.map((o) => ({
      ...o,
      type: o.seller?.address === objkt?.creator?.address ? 'salePrimary' : 'saleSecondary',
      price: o.swap?.price,
      quantity: 1,
      token: objkt,
    })),
    ...swaps.map((o) => ({
      ...o,
      type: 'listing',
      seller: o.creator,
      quantity: o.amount,
      token: objkt,
    })),
    {
      type: 'mint',
      timestamp: objkt.timestamp,
      seller: objkt.creator,
      quantity: objkt.supply,
      token: objkt,
      price: 0,
    },
  ], ['timestamp'], ['desc']) as Activity[];
  return (
    <Section style={ { marginTop: 40 } }>
      <SectionTitle>
        Activity (
        {formatAmount(items.length)}
        )
      </SectionTitle>
      <ActivityTable
        items={ items }
        filters={
          [
            'mint',
            'listing',
            'salePrimary',
            'saleSecondary',
            'transfer',
            'burn',
          ]
        }
        isObjktPage
      />
    </Section>
  );
};

const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 30px;
  margin-top: 15px;
  ${media.mobile`
    display: block;
  `}
`;

export const getOrderedSwaps = ({ swaps = [] }: Objkt): Swap[] => {
  const availableSupply = swaps.filter((e) => parseInt(e.contract_version, 10) === 2
    && parseInt(e.status, 10) === 0
    && e.is_valid);
  return orderBy(
    availableSupply,
    ['price', 'timestamp'],
    ['asc', 'desc'],
  );
};

const SwapItem: React.FC<{
  swap: Swap;
  objkt: Objkt;
  onCompleteAction: () => void;
}> = ({
  swap,
  objkt,
  onCompleteAction,
}) => {
  const { timestamp, creator, amount_left, price } = swap;
  const { acc } = useHicetnuncContext();
  const isFromCurrentUser = acc && acc.address === creator.address;
  return (
    <Tr key={ `swap.${swap.id}` }>
      <Td>{ calendar(timestamp && parseISO(timestamp)) }</Td>
      <Td>
        <UserTableItem user={ creator } />
        <div style={ { marginLeft: 'auto' } }>
          {
            isFromCurrentUser ? (
              <>
                <EditSwapButton swap={ swap } objkt={ objkt } onComplete={ onCompleteAction } />
                <CancelSwapButton swap={ swap } objkt={ objkt } onComplete={ onCompleteAction } />
              </>
            ) : null
          }
        </div>
      </Td>
      <Td style={ { textAlign: 'right' } }>
        { amount_left }
      </Td>
      <Td style={ { textAlign: 'right' } }>
        {`${formatAmount(price / DIVIDER, 2)} ${xtz}`}
      </Td>
    </Tr>
  );
};
const SellerSection: React.FC<{
  objkt: Objkt;
  onCompleteAction: () => void;
}> = ({
  objkt,
  onCompleteAction,
}) => {
  const sellers = getOrderedSwaps(objkt) || [];
  const [limit, setLimit] = React.useState<number>(5);
  const itemsLimited = sellers.slice(0, limit);
  const canShowMore = sellers.length > limit;
  const handleShowMore = () => setLimit(sellers.length);
  return (
    <>
      <Section>
        <SectionTitle>
          { `Listings (${sellers.length})` }
        </SectionTitle>
        <Table>
          <Thead>
            <Tr>
              <Th>Date</Th>
              <Th>Seller</Th>
              <Th style={ { textAlign: 'right' } }>Quantity</Th>
              <Th style={ { textAlign: 'right' } }>Price</Th>
            </Tr>
          </Thead>
          <Tbody>
            {
              itemsLimited.map((swap) => (
                <SwapItem
                  key={ `swap.${swap.id}` }
                  swap={ swap }
                  objkt={ objkt }
                  onCompleteAction={ onCompleteAction }
                />
              ))
            }
          </Tbody>
        </Table>
        {
          canShowMore ? (
            <ShowMoreButton onClick={ handleShowMore }>
              Show all listings
            </ShowMoreButton>
          ) : null
        }
      </Section>
    </>
  );
};

const OwnText = styled.div`
  color: ${({ theme }) => theme.colors.primary};
  font-weight: bold;
`;

const TitleRow = styled(FlexRow)`
  align-items: flex-start;
  ${media.tablet`
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  `}
`;

export const getPerformance = ({ firstPrice, lastPrice }) => lastPrice && firstPrice && firstPrice !== lastPrice && ((lastPrice) / firstPrice);

export const ObjktPerformance = ({ firstPrice, lastPrice, suffix = '' }) => {
  const performance = getPerformance({ firstPrice, lastPrice });
  const decimals = Math.abs(performance) < 10 ? 1 : 0;
  return performance ? (
    <>
      {
        performance >= 1 ? (
          <span style={ { color: colors.profit } }>
            { ` (x ${formatAmount(performance, decimals)}${suffix})` }
          </span>
        ) : (
          <span style={ { color: colors.alert } }>
            { ` (x ${formatAmount(performance, decimals)}${suffix})` }
          </span>
        )
      }
    </>
  ) : null;
};

const MiddleSection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 15px;
  ${media.mobile`
    display: block;
  `}
`;

const ObjktPage: React.FC<ObjktPageProps> = ({ objkt: objktRaw }) => {
  const router = useRouter();
  const { id: objktId } = router.query as unknown as { id: number };
  const { data: objktData = ({} as Objkt), status, refetch } = useObjktData(objktId);
  const { blockedWallets } = useDataContext();
  const isLoading = status === 'loading';
  const objkt = transformToken({
    ...objktRaw,
    ...objktData,
  }, blockedWallets);
  const {
    id,
    title,
    mimeType,
    creator,
    supply,
    isForSale,
    isSecondarySale,
    royalties,
    token_holders = [],
    minSwap,
    forSaleCount,
    firstPrice,
    lastPrice,
    averageCollectPrice,
  } = objkt;
  const { data: salesVolume, isLoading: isLoadingSalesVolume } = useObjktVolume(id);
  const isFromBlockedWallet = objktRaw?.isFromBlockedWallet || objktData?.isFromBlockedWallet;
  const holdings = useCurrentUserHoldings(objkt);
  const objktMetatags = getObjktMetatags(objkt) || {};
  const handleCompleteAction = () => setTimeout(() => refetch(), 1000);
  const shareUrl = `https://hic.af/o/${id}`;
  const shareText = `View this objkt on HIC.AF\n\n${shareUrl}`;
  const hasSecondarySales = true;
  return (
    <Layout>
      {
        !objkt
          ? <StatusText $center>Objkt not found</StatusText> : (
            <>
              <Head { ...objktMetatags } />
              {
                isFromBlockedWallet ? (
                  <StolenBanner>
                    BANNED ARTIST. The creator of this piece has been banned from hicetnunc because s.he did not respect the terms and conditions of the platform.
                  </StolenBanner>
                ) : null
              }
              <PageContainer>
                <div>
                  <TokenAssetWithModal objkt={ objkt } />
                </div>
                <div style={ { position: 'relative' } }>
                  {
                    objkt ? (
                      <>
                        <TopSection>
                          <TitleRow>
                            <div style={ { flex: 1 } }>
                              <Title>{ title || `#${objktId}` }</Title>
                              <TextLight style={
                                {
                                  fontWeight: 'bold',
                                  marginBottom: 5,
                                  position: 'relative',
                                  top: -2,
                                }
                              }
                              >
                                { `OBJKT#${objktId}` }
                              </TextLight>
                            </div>
                            <ButtonsRow>
                              <ObjktActionsButton address={ creator?.address } objkt={ objkt } />
                              <ShareButton url={ shareUrl } text={ shareText } />
                              {
                                holdings.total > 0 ? (
                                  <>
                                    <ObjktBurnButton objkt={ objkt } />
                                    <ObjktTransferButton objkt={ objkt } />
                                  </>
                                ) : null
                              }
                              <LikeButton id={ objktId } size={ 30 } />
                            </ButtonsRow>
                          </TitleRow>
                        </TopSection>
                        <TopSection>
                          {
                            isLoading ? (
                              <>
                                <StyledBuyButtonLoader />
                                <TextLoader style={ { marginBottom: 5 } } />
                              </>
                            ) : null
                          }
                        </TopSection>
                        <MiddleSection>
                          <>
                            {
                              isLoading ? (
                                <Section>
                                  <TextLoader style={ { marginBottom: 8 } } />
                                  <TextLoader style={ { marginBottom: 8 } } />
                                  <TextLoader style={ { marginBottom: 8 } } />
                                  <TextLoader style={ { marginBottom: 8 } } />
                                  <TextLoader style={ { marginBottom: 8 } } />
                                  <TextLoader style={ { marginBottom: 8 } } />
                                </Section>
                              ) : (
                                <>
                                  <Section>
                                    <div style={ { marginBottom: 5 } }>
                                      <TokenCreatorData objkt={ objkt } />
                                    </div>
                                    {
                                      supply === 1 && token_holders[0]?.holder?.address && token_holders[0]?.holder?.address !== creator?.address ? (
                                        <div style={ { marginBottom: 5 } }>
                                          <span style={ { marginRight: 6 } }>Collected by</span>
                                          <UserTableItem user={ token_holders[0]?.holder } />
                                        </div>
                                      ) : null
                                    }
                                    <div style={ { marginBottom: 5 } }>
                                      <div>
                                        {`${formatAmount(supply || 0)} edition${supply > 1 ? 's' : ''}`}
                                        {
                                          forSaleCount > 0 ? (
                                            ` / ${formatAmount(forSaleCount)} for sale`
                                          ) : null
                                        }
                                      </div>
                                      <div>{ `Royalties: ${royalties ? `${formatAmount(royalties / 10, 1)}%` : '-'}` }</div>
                                      <div>
                                        <span>{'MIME type: '}</span>
                                        <Link href={ `/search?mime=${encodeURIComponent(getObjktType(mimeType))}` }>
                                          {mimeType || '-'}
                                        </Link>
                                      </div>
                                    </div>
                                    <div style={ { marginBottom: 5 } }>
                                      <InfoSection { ...objkt } />
                                      <TagSection { ...objkt } />
                                    </div>
                                  </Section>
                                  <Section>
                                    {
                                      isForSale ? (
                                        <>
                                          <StyledBuyButton { ...objkt } />
                                          <div style={ { marginTop: 10, marginBottom: 6 } }>
                                            {
                                              isSecondarySale ? (
                                                <div>
                                                  <div>
                                                    {'Secondary sale by '}
                                                    <UserTableItem user={ minSwap.creator } />
                                                  </div>
                                                  {
                                                    firstPrice ? (
                                                      <div>{ `Originally sold for ${formatAmount(firstPrice, 2)} ${xtz}`}</div>
                                                    ) : null
                                                  }
                                                  {
                                                    firstPrice && lastPrice && hasSecondarySales ? (
                                                      <div>
                                                        <span>{`Last sold for ${formatAmount(lastPrice, 2)} ${xtz}`}</span>
                                                        <ObjktPerformance firstPrice={ firstPrice } lastPrice={ lastPrice } />
                                                      </div>
                                                    ) : null
                                                  }
                                                  {
                                                    averageCollectPrice ? (
                                                      <div>
                                                        <span>{`Collected for ${formatAmount(averageCollectPrice, 2)} ${xtz}`}</span>
                                                      </div>
                                                    ) : null
                                                  }
                                                </div>
                                              ) : (
                                                <div>Collect directly from the artist</div>
                                              )
                                            }
                                          </div>
                                        </>
                                      ) : null
                                    }
                                    {
                                      holdings.total > 0 ? (
                                        <div style={ { marginBottom: 10 } }>
                                          <StyledSellButton { ...objkt } />
                                          <OwnText style={ { marginTop: 6 } }>
                                            {`You own ${holdings.total} edition${holdings.total > 1 ? 's' : ''}`}
                                            {
                                              holdings.listings > 0 ? (
                                                <div>
                                                  {`You listed ${holdings.listings} for sale`}
                                                </div>
                                              ) : null
                                            }
                                          </OwnText>
                                        </div>
                                      ) : null
                                    }
                                    {/*
                                      holdings.listings > 0 ? (
                                        <div>
                                          <StyledUnlistButton { ...objkt } />
                                          <OwnText style={ { marginTop: 6 } }>
                                            {`${holdings.listings > 0 ? ` ${holdings.listings} for sale` : ''}`}
                                          </OwnText>
                                        </div>
                                      ) : null
                                    */}
                                    {
                                      salesVolume?.total > 0 ? (
                                        <div>
                                          <div>{ `Total sales volume: ${isLoadingSalesVolume ? '-' : `${formatAmount(salesVolume?.total || 0, 1)} ${xtz}`}` }</div>
                                          <div>{ `Primary sales volume: ${isLoadingSalesVolume ? '-' : `${formatAmount(salesVolume?.primary || 0, 1)} ${xtz}`}` }</div>
                                          <div>{ `Secondary sales volume: ${isLoadingSalesVolume ? '-' : `${formatAmount(salesVolume?.secondary || 0, 1)} ${xtz}`}` }</div>
                                        </div>
                                      ) : null
                                    }
                                  </Section>
                                </>
                              )
                            }
                          </>
                        </MiddleSection>
                      </>
                    ) : null
                  }
                </div>
              </PageContainer>
              <ActivityFeed { ...objkt } />
              <TwoColumnGrid>
                <CollectorSection { ...objkt } />
                <SellerSection objkt={ objkt } onCompleteAction={ handleCompleteAction } />
              </TwoColumnGrid>
              <Section style={ { marginTop: 30 } }>
                <Link href={ getUserLink(creator) } passHref>
                  <a>
                    <SectionTitle style={ { marginBottom: 10 } }>
                      {`More from ${creator?.name || 'this artist'}`}
                    </SectionTitle>
                  </a>
                </Link>
                <CreatorFeed address={ creator?.address } exclude={ [id] } rpp={ 4 } noShowMore />
              </Section>
            </>
          )
      }
    </Layout>
  );
};

export default ObjktPage;
