/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { BiDotsHorizontalRounded } from 'react-icons/bi';
import styled from 'styled-components';
import ExternalLink from '~/components/ExternalLink';
// import useCurrentUserHoldings from '~/hooks/useCurrentUserHoldings';
import { Objkt } from '~/types';
import { replaceIPFS2 } from '~/utils';
import ButtonSquare from './ButtonSquare';
import Dropdown, { DropdownIcon, DropdownItem } from './Dropdown';
import Emoji from './Emoji';

/*
import ObjktBurnButton from './ObjktBurnButton';
import ObjktTransferButton from './ObjktTransferButton';
*/

const ActionsButtonContainer = styled.div`
  position: relative;
`;

const StyledDropdown = styled(Dropdown)`
  bottom: 1px;
`;

const ObjktActionsButton: React.FC<{
  address: string;
  objkt: Objkt;
}> = ({
  address,
  objkt,
}) => {
  const { id: objktId, metadata, artifact_uri } = objkt;
  // const holdings = useCurrentUserHoldings(objkt);
  const [open, setOpen] = React.useState<boolean>(false);
  const buttonRef = React.useRef<HTMLButtonElement>();
  return (
    <ActionsButtonContainer>
      <ButtonSquare ref={ buttonRef } onClick={ () => setOpen((o) => !o) }>
        <BiDotsHorizontalRounded />
      </ButtonSquare>
      <StyledDropdown
        isOpen={ open }
        handleClose={ () => setOpen(false) }
        buttonRef={ buttonRef }
      >
        {/*
          holdings.quantity > 0 ? (
            <>
              <ObjktTransferButton objkt={ objkt } />
              <ObjktBurnButton objkt={ objkt } />
            </>
          ) : null
        */}
        <DropdownItem
          as={ ExternalLink }
          // eslint-disable-next-line max-len
          href={ `https://hen-mirror.hic.af/objkt/${objktId}` }
        >
          <DropdownIcon>
            <Emoji v="〓" style={ { transform: 'translateY(1px)' } } />
          </DropdownIcon>
          <span>View on hicetnunc</span>
        </DropdownItem>
        <DropdownItem
          as={ ExternalLink }
          // eslint-disable-next-line max-len
          href={ replaceIPFS2(metadata) }
        >
          <DropdownIcon>
            <Emoji v="🌍" style={ { transform: 'translateY(1px)' } } />
          </DropdownIcon>
          <span>View metadata on IPFS</span>
        </DropdownItem>
        <DropdownItem
          as={ ExternalLink }
          // eslint-disable-next-line max-len
          href={ replaceIPFS2(artifact_uri) }
        >
          <DropdownIcon>
            <Emoji v="📦" style={ { transform: 'translateY(1px)' } } />
          </DropdownIcon>
          <span>View original file</span>
        </DropdownItem>
        {
          objkt.display_uri ? (
            <DropdownItem
              as={ ExternalLink }
              href={ `https://www.google.com/searchbyimage?image_url=${replaceIPFS2(objkt.display_uri)}` }
            >
              <DropdownIcon>
                <Emoji v="🔎" style={ { transform: 'translateY(2px)' } } />
              </DropdownIcon>
              <span>Search image on Google</span>
            </DropdownItem>
          ) : null
        }
        <DropdownItem
          as={ ExternalLink }
          // eslint-disable-next-line max-len
          href={ `https://docs.google.com/forms/d/e/1FAIpQLSf8jaKpqukdLarsm8Ai1xKf9H1nlJQZopbTc5UPXWrvkJtoBA/viewform?usp=pp_url&entry.73971236=${address}&entry.567593262=${objktId}` }
        >
          <DropdownIcon>
            <Emoji v="🚨" style={ { transform: 'translateY(2px)' } } />
          </DropdownIcon>
          <span>Report copyminter</span>
        </DropdownItem>
      </StyledDropdown>
    </ActionsButtonContainer>
  );
};

export default ObjktActionsButton;
