/* eslint-disable max-len */
import styled from 'styled-components';
import React from 'react';
import { Formik, Form } from 'formik';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';
import { Objkt, Swap } from '~/types';
import Modal, { ModalHeader, ModalCloseButton, ModalBody } from '~/components/Modal';
import TextTruncated from './TextTruncated';
import Button from './Button';
import { SuccessIcon, TextError } from './SellButton';
import LoadIcon from './LoadIcon';
import Input from './Input';
import { DIVIDER } from '~/utils/const';
import { xtz } from '~/utils';

const SwapActionButton = styled.a`
  margin-right: 10px;
  font-weight: bold;
`;

const EditSwapButton: React.FC<{
  swap: Swap;
  objkt: Objkt;
  onComplete: () => void;
}> = ({
  swap,
  objkt,
  onComplete,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<'idle' | 'pending' | 'mining' | 'success'>('idle');
  const [error, setError] = React.useState<string>();
  const { editSwap, acc } = useHicetnuncContext();
  const handleClick = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleSubmit = async ({
    quantity,
    price,
  }) => {
    if (status === 'pending') return;
    try {
      setError(null);
      setStatus('pending');
      const op = await editSwap(swap.id, {
        owner: acc.address,
        creator: objkt.creator.address,
        objktId: +objkt.id,
        objktRoyalties: +objkt.royalties,
        ops: [{ quantity, price }],
      });
      if (op.confirmation) {
        setStatus('mining');
        await op?.confirmation();
        setStatus('success');
        onComplete();
      } else {
        setStatus('idle');
      }
    } catch (err) {
      setError(err.message);
      setStatus('idle');
    }
  };
  React.useEffect(() => {
    if (isOpen) {
      setStatus('idle');
      setError(null);
    }
  }, [isOpen]);
  return (
    <>
      <SwapActionButton onClick={ handleClick }>
        edit
      </SwapActionButton>
      <Modal isOpen={ isOpen } handleClose={ handleClose }>
        <ModalHeader>
          <TextTruncated as="h2" style={ { margin: 0 } }>
            { `Edit swap on ${objkt.title}` }
          </TextTruncated>
          <ModalCloseButton onClick={ () => setIsOpen(false) } />
        </ModalHeader>
        <ModalBody style={ { padding: 20, textAlign: 'center' } }>
          {
            status === 'idle' || status === 'pending' ? (
              <>
                <Formik
                  enableReinitialize
                  initialValues={
                    {
                      price: swap.price / DIVIDER,
                      quantity: swap.amount_left,
                    }
                  }
                  onSubmit={ handleSubmit }
                >
                  {
                    ({ values, setFieldValue }) => (
                      <Form>
                        <div style={
                          {
                            margin: '15px auto',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            textAlign: 'left',
                          }
                        }
                        >
                          <label>
                            <div style={ { marginBottom: 5 } }>Quantity</div>
                            <Input
                              type="number"
                              step="1"
                              placeholder="Quantity"
                              min={ 1 }
                              max={ swap.amount_left }
                              value={ values.quantity }
                              onChange={ (e) => setFieldValue('quantity', +e.target.value) }
                              style={ { width: 120, marginRight: 15 } }
                            />
                          </label>
                          <label>
                            <div style={ { marginBottom: 5 } }>
                              Price (
                              {xtz}
                              )
                            </div>
                            <Input
                              type="number"
                              step="0.01"
                              placeholder="Price"
                              min={ 0 }
                              value={ values.price }
                              onChange={ (e) => setFieldValue('price', +e.target.value) }
                              style={ { width: 120 } }
                            />
                          </label>
                        </div>
                        <Button onClick={ handleClose } style={ { marginRight: 10 } }>
                          Cancel
                        </Button>
                        <Button type="submit" $primary disabled={ status === 'pending' }>
                          {status === 'pending' ? 'Editing...' : 'Edit'}
                        </Button>
                        {
                          error ? (
                            <TextError style={ { marginTop: 10 } }>
                              {`Something went wrong: ${error}`}
                            </TextError>
                          ) : null
                        }
                      </Form>
                    )
                  }
                </Formik>
              </>
            ) : status === 'mining' ? (
              <div>
                <div style={ { marginBottom: 10 } }>
                  Your modification is being sent to the Tezos blockchain. It may take a few seconds to complete. You can close this window if you want, it won't affect the transaction.
                </div>
                <LoadIcon $animating size={ 30 } />
              </div>
            ) : status === 'success' ? (
              <div>
                <div style={ { marginBottom: 5 } }><SuccessIcon /></div>
                <div style={ { marginBottom: 10 } }>Your swap has been modified, looks great!</div>
                <Button onClick={ handleClose }>
                  Go on with my life
                </Button>
              </div>
            ) : status === 'error' ? (
              <div>Something went wrong, please retry</div>
            ) : null
          }
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditSwapButton;
