/* eslint-disable max-len */
import styled from 'styled-components';
import React from 'react';
import useHicetnuncContext from '~/contexts/Hicetnunc/useHicetnuncContext';
import { Objkt, Swap } from '~/types';
import Modal, { ModalHeader, ModalCloseButton, ModalBody } from '~/components/Modal';
import TextTruncated from './TextTruncated';
import Button from './Button';
import { SuccessIcon, TextError } from './SellButton';
import LoadIcon from './LoadIcon';

const SwapActionButton = styled.a`
  margin-right: 10px;
  font-weight: bold;
`;

const CancelSwapButton: React.FC<{
  swap: Swap;
  objkt: Objkt;
  onComplete: () => void;
}> = ({
  swap,
  objkt,
  onComplete,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { id } = swap;
  const [status, setStatus] = React.useState<'idle' | 'pending' | 'mining' | 'success'>('idle');
  const [error, setError] = React.useState<string>();
  const { cancel } = useHicetnuncContext();
  const handleClick = () => setIsOpen(true);
  const handleClose = () => setIsOpen(false);
  const handleCancelSwap = async () => {
    if (status === 'pending') return;
    try {
      setError(null);
      setStatus('pending');
      const op = await cancel(id);
      if (op.confirmation) {
        setStatus('mining');
        await op?.confirmation();
        setStatus('success');
        onComplete();
      } else {
        setStatus('idle');
      }
    } catch (err) {
      setError(err.message);
      setStatus('idle');
    }
  };
  React.useEffect(() => {
    if (isOpen) {
      setStatus('idle');
      setError(null);
    }
  }, [isOpen]);
  return (
    <>
      <SwapActionButton onClick={ handleClick }>
        cancel
      </SwapActionButton>
      <Modal isOpen={ isOpen } handleClose={ handleClose }>
        <ModalHeader>
          <TextTruncated as="h2" style={ { margin: 0 } }>
            { `Cancel swap on ${objkt.title}` }
          </TextTruncated>
          <ModalCloseButton onClick={ () => setIsOpen(false) } />
        </ModalHeader>
        <ModalBody style={ { padding: 30, textAlign: 'center' } }>
          {
            status === 'idle' || status === 'pending' ? (
              <>
                <div style={ { marginBottom: 0 } }>You are about to cancel your listing.</div>
                <div style={ { marginBottom: 15 } }>Are you sure?</div>
                <Button onClick={ handleClose } style={ { marginRight: 10 } }>
                  No, take me back
                </Button>
                <Button onClick={ handleCancelSwap } $primary disabled={ status === 'pending' }>
                  {status === 'pending' ? 'Cancelling...' : 'Yes'}
                </Button>
                {
                  error ? (
                    <TextError style={ { marginTop: 10 } }>
                      {`Something went wrong: ${error}`}
                    </TextError>
                  ) : null
                }
              </>
            ) : status === 'mining' ? (
              <div>
                <div style={ { marginBottom: 10 } }>
                  Your cancellation is being sent to the Tezos blockchain. It may take a few seconds to complete. You can close this window if you want, it won't affect the transaction.
                </div>
                <LoadIcon $animating size={ 30 } />
              </div>
            ) : status === 'success' ? (
              <div>
                <div style={ { marginBottom: 5 } }><SuccessIcon /></div>
                <div style={ { marginBottom: 10 } }>Your swap has been cancelled, you can relax now.</div>
                <Button onClick={ handleClose }>
                  Continue
                </Button>
              </div>
            ) : status === 'error' ? (
              <div>Something went wrong, please retry</div>
            ) : null
          }
        </ModalBody>
      </Modal>
    </>
  );
};

export default CancelSwapButton;
